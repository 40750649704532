
  import { defineComponent, reactive, computed, ref, onMounted } from 'vue';
  import { useScreen } from 'vue-screen';
  import { useVuelidate } from "@vuelidate/core";
  import { sameAs, helpers } from "@vuelidate/validators";
  import { routesNames, router, useRoute } from '@/router';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppButton from '@/components/stateless/AppButton.vue';

  import { IErrorFields, IConfirmForgotPasswordForm } from '@/types';
  import { verificationFormRules, signUpValidationRules } from './auth-validation-rules';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { authService } from '@/services';

  export default defineComponent({
    name: 'ConfirmForgotPassword',

    components: { AppInput, AppButton },

    setup() {
      const route = useRoute();
      const state = reactive<IConfirmForgotPasswordForm>({
        code: '',
        password: '',
        confirmPassword: '',
        userName: ''
      });

      let isPasswordChanged = ref<boolean>(false);

      const errorMessages = reactive<IErrorFields>({ code: '', password: '', confirmPassword: '' });
      const rules = computed<any>(() => ({
        code: verificationFormRules.verificationCode,
        password: signUpValidationRules.password,
        confirmPassword: {
          required: signUpValidationRules.password.required,
          sameAs: helpers.withMessage('Password doesn\'t matched', sameAs(state.password))
        },
        userName: {}
      }));

      const screen = useScreen();

      const v$ = useVuelidate(rules, state);

      const hintOptions = computed<any>(() => [
        { message: 'At least one Capital letter', matched: !v$.value.password.containsUppercase.$invalid },
        { message: '8 characters minimum', matched: !v$.value.password.minLength.$invalid && v$.value.password.$model },
        { message: 'At least one Number', matched: !v$.value.password.containsNumber.$invalid }
      ]);


      function checkParams() {
        const { email } = route.params;

        if (email && typeof email === 'string') {
          state.userName = email;
        } else {
          router.push({ name: routesNames.forgotPassword });
        }
      }

      async function onSubmit() {
        if (await v$.value.$validate()) {
          authService.forgotPasswordSubmit(state)
            .then(() => { isPasswordChanged.value = true; })
            .catch((err) => { errorMessages.code = !err.message ? err : err.message; });
        } else {
          handleSetErrors(v$.value.$errors, errorMessages);
        }
      }

      onMounted(() => checkParams());

      return {
        errorMessages,
        isPasswordChanged,
        router,
        routesNames,
        clearErrorField,
        state,

        hintOptions,
        screen,
        v$,

        validateField: (name: string) => validateField(name, v$, errorMessages),
        onSubmit,
      };
    }
  });
