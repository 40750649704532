<template>
  <div class="bg-white px-25 py-25 md:px-40 md:py-40 rounded-5">
    <!-- C H A N G E  P A S S W O R D  S T E P -->
    <div v-if="!isPasswordChanged">
      <div class="mb-40">
        <h1 class="text-dark-cl-20 text-1xl sm:text-3xl mb-25">Change Password</h1>
        <p class="text-grey-fp-60 text-sm sm:text-base">
          If the account exists, we have sent a password reset code to
          <span class="text-primary">{{ state.userName }}</span>.
        </p>
        <p class="text-grey-fp-60 text-sm sm:text-base">Enter it below to reset your password.</p>
      </div>

      <!-- F O R M -->
      <form
        data-test="confirm-forgot-password-form"
        @submit.prevent="onSubmit"
      >
        <AppInput
          v-model="v$.code.$model"
          name="code"
          label="Code"
          class="mb-30"
          :size="screen.width < 640 ? 'medium' : 'large'"
          :error="errorMessages.code"
          @focus="clearErrorField('code', errorMessages)"
          @blur="validateField('code')"
        />

        <AppInput
          v-model="v$.password.$model"
          name="passoword"
          label="Password"
          type="password"
          class="mb-25"
          use-hint
          :hintOptions="hintOptions"
          :size="screen.width < 640 ? 'medium' : 'large'"
          :error="errorMessages.password"
          @focus="clearErrorField('password', errorMessages)"
          @blur="validateField('password')"
        />

        <AppInput
          v-model="v$.confirmPassword.$model"
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          class="mb-25"
          :size="screen.width < 640 ? 'medium' : 'large'"
          :error="errorMessages.confirmPassword"
          @focus="clearErrorField('confirmPassword', errorMessages)"
          @blur="validateField('confirmPassword')"
        />

        <!-- A C T I O N S -->
        <AppButton
          type="primary"
          :size="screen ? 'medium' : 'large'"
          class="w-full mb-20"
        >
          Change Password
        </AppButton>

        <div class="flex items-center justify-center text-sm sm:text-base">
          <p class="text-grey-fp-60">
            Back to <router-link
              :to="{ name: 'Login' }"
              class="text-primary"
            >
              log in?
            </router-link>
          </p>
        </div>
      </form>
    </div>

    <!-- S U C C E S S F U L L  S T E P -->
    <div v-else>
      <div class="mb-40">
        <h1 class="text-dark-cl-20 text-1xl sm:text-3xl mb-25">Your password was successfully changed</h1>
      </div>
      <AppButton
        type="primary"
        :size="screen.width < 640 ? 'medium' : 'large'"
        class="w-full mb-20 mt-35"
        @click="router.push({ name: routesNames.login });"
      >
        Go to Login
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, computed, ref, onMounted } from 'vue';
  import { useScreen } from 'vue-screen';
  import { useVuelidate } from "@vuelidate/core";
  import { sameAs, helpers } from "@vuelidate/validators";
  import { routesNames, router, useRoute } from '@/router';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppButton from '@/components/stateless/AppButton.vue';

  import { IErrorFields, IConfirmForgotPasswordForm } from '@/types';
  import { verificationFormRules, signUpValidationRules } from './auth-validation-rules';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { authService } from '@/services';

  export default defineComponent({
    name: 'ConfirmForgotPassword',

    components: { AppInput, AppButton },

    setup() {
      const route = useRoute();
      const state = reactive<IConfirmForgotPasswordForm>({
        code: '',
        password: '',
        confirmPassword: '',
        userName: ''
      });

      let isPasswordChanged = ref<boolean>(false);

      const errorMessages = reactive<IErrorFields>({ code: '', password: '', confirmPassword: '' });
      const rules = computed<any>(() => ({
        code: verificationFormRules.verificationCode,
        password: signUpValidationRules.password,
        confirmPassword: {
          required: signUpValidationRules.password.required,
          sameAs: helpers.withMessage('Password doesn\'t matched', sameAs(state.password))
        },
        userName: {}
      }));

      const screen = useScreen();

      const v$ = useVuelidate(rules, state);

      const hintOptions = computed<any>(() => [
        { message: 'At least one Capital letter', matched: !v$.value.password.containsUppercase.$invalid },
        { message: '8 characters minimum', matched: !v$.value.password.minLength.$invalid && v$.value.password.$model },
        { message: 'At least one Number', matched: !v$.value.password.containsNumber.$invalid }
      ]);


      function checkParams() {
        const { email } = route.params;

        if (email && typeof email === 'string') {
          state.userName = email;
        } else {
          router.push({ name: routesNames.forgotPassword });
        }
      }

      async function onSubmit() {
        if (await v$.value.$validate()) {
          authService.forgotPasswordSubmit(state)
            .then(() => { isPasswordChanged.value = true; })
            .catch((err) => { errorMessages.code = !err.message ? err : err.message; });
        } else {
          handleSetErrors(v$.value.$errors, errorMessages);
        }
      }

      onMounted(() => checkParams());

      return {
        errorMessages,
        isPasswordChanged,
        router,
        routesNames,
        clearErrorField,
        state,

        hintOptions,
        screen,
        v$,

        validateField: (name: string) => validateField(name, v$, errorMessages),
        onSubmit,
      };
    }
  });
</script>

<style lang="scss" scoped>
  .password-hint {
    @apply absolute bg-white p-10 rounded-5 shadow z-10;

    @media (min-width: 1120px) {
      @apply top-0 -right-230;
      &:before {
        content: "\A";
        border-style: solid;
        border-width: 10px 10px 10px 0;
        border-color: transparent #f3f3f3 transparent transparent;
        position: absolute;
        top: calc(100% / 2 - 12px);
        left: -10px;
      }
    }

    @media (max-width: 1120px) {
      @apply transform translate-x-1/2 left-1/2 -top-100;
      &:before {
        content: "\A";
        position: absolute;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: #f3f3f3 transparent transparent transparent;
        left: calc(100% / 2 - 12px);
        bottom: -10px;
      }
    }
  }
</style>